<template>
  <div class="settlement-apply-wrapper">
    <div class="item" v-for="(item, index) of list" :key="index">
      <div class="item-title line">
        <div class="item-title-txt">
          理赔信息
        </div>
        <div class="active">
          {{ item.stausEn }}
        </div>
      </div>
      <div class="item-detail">
        <div class="item-baodan">
          姓名: {{ item.name }}
        </div>
        <div class="item-baodan">
          电话: {{ item.telephone }}
        </div>
        <div class="item-baodan">
          理赔详情: {{ item.memo }}
        </div>
        <div class="item-baodan">保单号：{{ item.policyNo }}</div>
        <div class="item-baodan">
          创建时间：{{ item.gmtCreate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './SettlementRecord.less';
import { getSingleList } from '../../../api/api';

export default {
  name: 'SettlementRecord',
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getSettlementList();
  },
  methods: {
    async getSettlementList()
    {
      //获取理赔记录
      const data = await getSingleList({  
        openid: JSON.parse(localStorage.getItem('loginInfo')).openId,
        unionid: JSON.parse(localStorage.getItem('loginInfo')).unitId
      });
      this.list = data.data;

      this.list.forEach(res => {
        switch(res.claimStatus)
        {
          case 'CLAIM_END': res.stausEn = '已完结';break;
          case 'CLAIM_ING': res.stausEn = '理赔中';break;
          case 'CLAIM_STATUS': res.stausEn = '已申请';break;
        }
      });
    }
  },
}
</script>

